import React, { useState } from "react";
import { Head } from "@inertiajs/react";

import LandingPageLayout from "../../Layouts/LandingPageLayout";
import FooterMarketingPages from "../../components/UI/FooterMarketingPages";
import InlineLink from "../../components/UI/InlineLink";

export default function NotFoundPage({ auth }) {
    return (
        <LandingPageLayout auth={auth}>
            <Head title="Page not found!" />
            <div className="relative isolate overflow-hidden">
                <div className="mx-auto max-w-7xl px-6 pb-16 pt-10 sm:pb-24">
                    <div className="mx-auto lg:mx-0 lg:flex-shrink-0 lg:pt-8">
                        <h1 className="font-titan-one text-4xl tracking-tight text-astronaut-blue sm:text-6xl">
                            Page not found!
                        </h1>

                        <div className="bg-amber-50 text-sky-900 text-lg overflow-hidden shadow-sm rounded-3xl my-6 py-8 px-4">
                            <p className="my-2">
                                Oops! It looks like you've wandered into
                                uncharted territory.
                            </p>
                            <p className="my-2">
                                The page you're looking for seems to have gone
                                on an adventure without us. Don't worry, even
                                the best explorers sometimes take a wrong turn.
                            </p>
                            <h2>Why not try one of these options:</h2>
                            <ul className="list-disc ml-8">
                                <li>
                                    Return to our
                                    <InlineLink href="/" className="mr-1">
                                        homepage
                                    </InlineLink>
                                    and start a new journey
                                </li>
                            </ul>
                            <p className="my-2">
                                If you believe this page should exist, please
                                <InlineLink href={route("contact")}>
                                    contact our support team
                                </InlineLink>
                                . We're here to help guide you back to familiar
                                ground!
                            </p>
                            <p className="my-2">
                                Remember, not all who wander are lost... but in
                                this case, we might need a better map.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <FooterMarketingPages divClassName="!mt-0 !md:mt-16 !lg:mt-16" />
        </LandingPageLayout>
    );
}
